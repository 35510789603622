/* eslint-disable no-console */

// BUILD NUMBER 20250113.404.main

import Vue from 'vue';

// eslint-disable-next-line prefer-const
let environmentName = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';
// eslint-disable-next-line prefer-const
let ringName = process.env.NODE_ENV === 'production' ? 'user' : 'user';
if (environmentName === 'prod' && ringName === 'user' && localStorage['To-Do.Studio/App/Debug_VueJS'] !== 'true') {
  Vue.config.devtools = false;
} else {
  Vue.config.devtools = true;
}

import './plugins/axios';
import vuetify from './plugins/vuetify';
import './plugins/svg';
import App from './App.vue';
import router, { Initialize as RouterInitialize } from './plugins/router';
import appInsights from './plugins/applicationInsights';
import store, { VueXMagicInstance } from './plugins/vuex';
import i18n from './plugins/i18n';
import Toasted from 'vue-toasted';
import { Initialize as AppStorageInitialize } from './plugins/AppStorage';
import * as AuthSideCar from '@/modules/AuthenticationModuleSideCar';

import '@/modules/AppModule';
import '@/modules/ConfigModule';
import '@/modules/AuthenticationModule';
import '@/modules/StudioModule';
import '@/modules/UserModule';
import '@/modules/NotificationModule';
import '@/modules/TipModule';

import './registerServiceWorker';
import * as AppModuleDomain from '@/models/AppModuleDomain';
import * as ConfigModuleDomain from '@/models/ConfigModuleDomain';
import * as AuthenticationModuleDomain from '@/models/AuthenticationModuleDomain';
import Services from './services/Services';
import { initialize as initializePersistanceHelper } from '@/helpers/PersistanceHelper';
import { initialize as initializeToDoHelper } from '@/helpers/ToDoHelper';
import { initialize as initializeCopilotHelper } from '@/helpers/CopilotHelper';
import { SetOidcUserManager as axiosSetOidcUserManager } from './plugins/axios';
import eruda, { Info } from 'eruda';
import * as I18NHelper from '@/helpers/I18NHelper';

Vue.config.productionTip = false;

let debugPrefix: string = '[100m To.Do Studio [49m';

Vue.config.errorHandler = (err) => { // signature is : (err, vm, info)
  if (!(process.env.NODE_ENV === 'production' &&
    store.state.config.environmentName.toLowerCase() === 'production')) {
    Services.Toaster.error('Exception logged to console:\n' + err.message);
  }

  appInsights.trackException({ exception: err });

  // eslint-disable-next-line no-console
  console.error(debugPrefix, err);

  // tslint:disable-next-line:no-empty
  router.push({ name: 'error-with-code', params: { code: 'unknown', devMessage: err.message } }).catch(() => { });
};

Vue.use(Toasted, {
  keepOnHover: true,
  iconPack: 'callback',
  duration: 2500,
});

let myVue;
let initializeDone = false;

let bootupInterval: NodeJS.Timeout;
let bootupCounter = 1000;

if (localStorage['To-Do.Studio/App/Debug_Interface'] === 'true') {
  eruda.init({
    //container: el,
    tool: ['console', 'elements', 'info', 'network', 'resources'],
    useShadowDom: true,
    autoScale: true,
    defaults: {
      displaySize: 50,
      transparency: 0.9,
      theme: 'Material Oceanic',
    },
  });
  const info = eruda.get('info');
  if (info) {
    (info as Info).remove('Backers');
    (info as Info).remove('About');
    (info as Info).add('Location', () => location.href);
    (info as Info).add('Device', () => [
      '<table><tbody>',
      `<tr><td class="eruda-device-key">screen</td><td>${screen.width} * ${screen.height}</td></tr>`,
      `<tr><td>viewport</td><td>${window.innerWidth} * ${window.innerHeight}</td></tr>`,
      `<tr><td>pixel ratio</td><td>${window.devicePixelRatio}</td></tr>`,
      '</tbody></table>',
    ].join(''));
    (info as Info).add('Vuetify', () => myVue ? [
      '<table><tbody>',
      `<tr><td>breakpoint name</td><td>${myVue.$vuetify.breakpoint.name}</td></tr>`,
      `<tr><td>breakpoint width</td><td>${myVue.$vuetify.breakpoint.width}</td></tr>`,
      `<tr><td>breakpoint height</td><td>${myVue.$vuetify.breakpoint.height}</td></tr>`,
      '</tbody></table>',
    ].join('') : '');
  }
  const entryBtn = eruda.get('entryBtn');
  if (entryBtn) {
    (entryBtn as any).on('click', () => {
      (info as any)._render();
    });
  }
}

async function initialize(): Promise<string> {
  await AppStorageInitialize();
  await RouterInitialize();
  await initializePersistanceHelper();
  await initializeToDoHelper();
  await initializeCopilotHelper();

  debugPrefix = Services.GetTDSChalkCategory('To-Do Studio');
  console.info(debugPrefix, 'Initializing...');

  I18NHelper.setLocale(i18n, I18NHelper.getClosestLocale(navigator.language));

  if (window.location.pathname.toLowerCase() === '/force-reset') {
    await Services.ResetLocalDatabases(true);

    localStorage.clear();

    //if broadcasting, reset all other tabs
    if (typeof window.BroadcastChannel === 'function') {
      const broadcastId = 'master';
      const broadcastChannel = new window.BroadcastChannel('TDS');
      broadcastChannel.postMessage(`${broadcastId}!Event|sign-out`);
    }

    bootupCounter = 0;
    window.location.replace('/sign-in/reset');
    return 'FORCE-RESET'; // reboot
  }
  if (window.location.pathname.toLowerCase() === '/force-signout') {
    let authority = 'https://todostudiosolutionauth.b2clogin.com/a460df9d-2057-47e1-a6c1-7bb8f4353037/B2C_1_susi/v2.0';
    if (authority === '' || authority[0] === '#') {
      authority = 'https://todostudiosolutionAuth.b2clogin.com/a460df9d-2057-47e1-a6c1-7bb8f4353037/B2C_1_susi/v2.0';
    }
    
    const signOutUrl = authority.toLowerCase().replace('/b2c_1_susi/v2.0', '/b2c_1_susi/oauth2/v2.0/logout?post_logout_redirect_uri=' + window.location.origin + '/');

    bootupCounter = 0;
    window.location.replace(signOutUrl);
    return 'FORCE-SIGNOUT'; // reboot
  }
  if (window.location.pathname.toLowerCase() === '/force-dev') {
    localStorage['To-Do.Studio/App/Debug_Interface'] = true;
    localStorage['To-Do.Studio/App/Debug_Router'] = true;
    localStorage['To-Do.Studio/App/Debug_VueJS'] = true;
    localStorage['To-Do.Studio/App/Debug_VuexMagic'] = true;
    localStorage['To-Do.Studio/App/Debug_AuthenticationModule'] = true;
    localStorage['To-Do.Studio/App/Debug_PersistanceHelper'] = true;
    localStorage['To-Do.Studio/App/Debug_ToDoHelper'] = true;
    localStorage['To-Do.Studio/App/Debug_CopilotHelper'] = true;
    localStorage['To-Do.Studio/App/Debug_PersistanceHelperDeep'] = true;
    localStorage['To-Do.Studio/App/Override_VuexMagic_SyncIntervalInMilliseconds'] =  10 * 1000; // 10 seconds
    
    bootupCounter = 0;
    window.location.replace('/');
    return 'FORCE-DEV'; // reboot
  }
  if (window.location.pathname.toLowerCase() === '/force-undev') {
    localStorage.removeItem('To-Do.Studio/App/Debug_Interface');
    localStorage.removeItem('To-Do.Studio/App/Debug_Router');
    localStorage.removeItem('To-Do.Studio/App/Debug_VueJS');
    localStorage.removeItem('To-Do.Studio/App/Debug_VuexMagic');
    localStorage.removeItem('To-Do.Studio/App/Debug_AuthenticationModule');
    localStorage.removeItem('To-Do.Studio/App/Debug_PersistanceHelper');
    localStorage.removeItem('To-Do.Studio/App/Debug_ToDoHelper');
    localStorage.removeItem('To-Do.Studio/App/Debug_CopilotHelper');
    localStorage.removeItem('To-Do.Studio/App/Debug_PersistanceHelperDeep');
    localStorage.removeItem('To-Do.Studio/App/Override_VuexMagic_SyncIntervalInMilliseconds');
    
    bootupCounter = 0;
    window.location.replace('/');
    return 'FORCE-UNDEV'; // reboot
  }
  if (window.location.pathname.toLowerCase() === '/force-update') {
    // do nothing, actual effect was done in registerServiceWorker.ts
    
    bootupCounter = 0;
    window.location.replace('/');
    return 'FORCE-UPDATE'; // reboot
  }

  await store.dispatch(ConfigModuleDomain.InitializeAction.ActionName, new ConfigModuleDomain.InitializeAction());

  if (store.getters['config/isServerConfigured']) {
    await AuthSideCar.initializeUserManager(store.state.config.oidcSettings);

    if (window.location.pathname === '/sign-out-callback') {
      console.info(debugPrefix, 'Processing OIDC Sign-Out');
      await AuthSideCar.getUserManager()?.signoutCallback();
      bootupCounter = 0;
      window.location.replace('/sign-in/sign-out');
      return 'OIDC sign-out callback'; // not sure how invoked, so always reboot
    }
    
    if (window.location.pathname === '/sign-in-callback') {
      console.info(debugPrefix, 'Processing OIDC Redirect Sign-In');
      const user = await AuthSideCar.getUserManager()?.signinRedirectCallback();
      let url = '/';
      if (user && user.state) { url= user!.state as string; }
      window.history.replaceState(null, '', url);
    } else if (window.location.pathname === '/sign-in-silent-callback') {
      console.info(debugPrefix, 'Processing OIDC Silent Sign-In');
      await AuthSideCar.getUserManager()?.signinSilentCallback();
      bootupCounter = 0;
      return 'OIDC sign-in callback - iframe'; // invoked in an iframe
    } else if (window.location.pathname === '/sign-in-popup-callback') {
      console.info(debugPrefix, 'Processing OIDC Popup Sign-In');
      await AuthSideCar.getUserManager()?.signinPopupCallback();
      bootupCounter = 0;
      return 'OIDC sign-in callback - popup'; // invoked in a popup
    }

    const isSignedIn = await AuthSideCar.isSignedIn();
    const idTokenIsExpired = await AuthSideCar.isIDTokenExpired();
    const accessTokenIsExpired = await AuthSideCar.isAccessTokenExpired();
    const isOnline = AuthSideCar.isOnline();
    if (isSignedIn && (idTokenIsExpired || accessTokenIsExpired) && isOnline) {
      console.info(debugPrefix, 'Signed in - Authentication tokens expired');
      
      // try a silent refresh, if there is a refresh_token this yields to a much better experience because of no redirect but a hxr request...
      let silentSignInSuccess = false;
      try {
        const silentUser = await AuthSideCar.getUserManager()?.signinSilent();
        if (silentUser) {
          console.info(debugPrefix, 'Signed in - Authentication tokens expired - Silent Refresh Succeeded');
          silentSignInSuccess = true;
        } else {
          console.info(debugPrefix, 'Signed in - Authentication tokens expired - Silent Refresh Failed');
        }
      } catch (err: any) {
        console.info(debugPrefix, 'Signed in - Authentication tokens expired - Silent Refresh error', err);
      }

      if (silentSignInSuccess === false) {

        await AuthSideCar.getUserManager()?.signinRedirect({ state: window.location.pathname + window.location.search });
        bootupCounter = 0; return "Redirecting to Authorize()";
      }
    } else if(isSignedIn && (idTokenIsExpired || accessTokenIsExpired) && isOnline === false) {
      console.info(debugPrefix, 'Signed in but offline - Authentication tokens expired');
    } else if (isSignedIn) {
      console.info(debugPrefix, 'Signed in - Authentication tokens valid');
    } else {
      console.info(debugPrefix, 'Not Signed in');
    }

    axiosSetOidcUserManager(AuthSideCar.getUserManager()!);
  }

  await VueXMagicInstance.PreAppModuleDomainInitializeAction();
  await store.dispatch(AuthenticationModuleDomain.InitializeAction.ActionName, new AuthenticationModuleDomain.InitializeAction());
  await store.dispatch(AppModuleDomain.InitializeAction.ActionName, new AppModuleDomain.InitializeAction());
  await VueXMagicInstance.PostAppModuleDomainInitializeAction();

  // eslint-disable-next-line no-console
  console.info(debugPrefix, `(${store.getters['config/version']}) ${store.state.config.environmentName} ${store.state.config.ringName} Initialized...`);
  initializeDone = true;
  return 'Success';
}

initialize().then((reason) => {

  if (bootupCounter === 0) { console.info(debugPrefix, 'Bootup cancelled: ' + reason); return; }

  bootupInterval = setInterval(() => {
    // console.info(debugPrefix, `Checking bootup bootupCounter=${bootupCounter} initializeDone=${initializeDone} vuexMagicActivityCounter=${VueXMagicInstance.activityCounter} `);
    if ((initializeDone === true && VueXMagicInstance.activityCounter === 0) || bootupCounter === 0) {
      clearInterval(bootupInterval);

      // console.info(debugPrefix, 'bootup finished');
      if (bootupCounter === 0) { console.error(debugPrefix, `Bootup failed bootupCounter=${bootupCounter} initializeDone=${initializeDone} vuexMagicActivityCounter=${VueXMagicInstance.activityCounter}`); return; }
      myVue = new Vue({
        vuetify,
        router,
        store,
        i18n,
        render: (h) => h(App),
        mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
      });

      myVue.$mount('#app');
      
    }
    bootupCounter--;
  }, 20);
});
